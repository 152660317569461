import {
  mdiLanguageJavascript,
  mdiReact,
  mdiLanguageHtml5,
  mdiLanguageCss3,
  mdiBootstrap,
  mdiDocker,
  mdiAws,
  mdiLanguagePython,
  mdiLanguageJava,
  mdiDatabaseSearch,
  mdiGit,
  mdiServerSecurity,
  mdiPenguin,
} from "@mdi/js";
export const technologies = [
  [
    { name: "JavaScript", percent: 89, icon: mdiLanguageJavascript },
    { name: "React", percent: 90, icon: mdiReact },
    { name: "HTML5", percent: 90, icon: mdiLanguageHtml5 },
    { name: "CSS3", percent: 85, icon: mdiLanguageCss3 },
    { name: "Bootstrap", percent: 80, icon: mdiBootstrap },
    { name: "Docker", percent: 88, icon: mdiDocker },
    { name: "AWS", percent: 50, icon: mdiAws },
  ],
  [
    { name: "Python", percent: 92, icon: mdiLanguagePython },
    { name: "Django", percent: 86, icon: mdiLanguagePython },
    { name: "Java", percent: 95, icon: mdiLanguageJava },
    { name: "SQL", percent: 85, icon: mdiDatabaseSearch },
    { name: "Git", percent: 89, icon: mdiGit },
    { name: "NGINX", percent: 79, icon: mdiServerSecurity },
    { name: "Linux", percent: 80, icon: mdiPenguin },
  ],
];
